const themeConfig = {
  token: {
    colorPrimary: "#30688e",
    colorInfo: "#30688e",
    colorTextBase: "#27374d",
    colorBgBase: "#f1f5f9",
    colorLink: "#3282b8",
    colorSuccess: "#2ecc71",
    colorWarning: "#f39c12",
    colorError: "#e74c3c",
    colorTextSecondary: "#526d82",
    colorBgContainer: "#ffffff",
    colorBorder: "#d1d8e0",
    fontFamily: "'Arial', sans-serif",
    fontSize: 14,
    borderRadius: 4,
    marginXS: 8,
    marginSM: 12,
    margin: 16,
    marginMD: 20,
    marginLG: 24,
    marginXL: 32,
  },
  components: {
    Button: {
      colorPrimaryHover: "#3282b8",
    },
    Menu: {
      colorItemBgSelected: "#cce0f0",
      colorItemTextSelected: "#30688e",
    },
    Table: {
      colorBgContainer: "#ffffff",
      colorBorderSecondary: "#e2e8f0",
    },
    Card: {
      colorBorderSecondary: "#e2e8f0",
    },
    Select: {
      colorBorder: "#d1d8e0",
      colorPrimaryHover: "#3282b8",
    },
    Input: {
      colorBorder: "#d1d8e0",
      colorPrimaryHover: "#3282b8",
    },
  },
};

export default themeConfig;
