import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuth } from "@/store/features/auth/authSlice";
import { useUserProfileQuery } from "@/store/features/auth/authApiSlice";
import { apiSlice } from "@/store/features/api/apiSlice";

const useUserProfile = () => {
  const shouldSkipQuery = !localStorage.getItem("token");
  const {
    data: userProfileData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useUserProfileQuery(undefined, { skip: shouldSkipQuery });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      localStorage.removeItem("token");
      dispatch(apiSlice.util.resetApiState());
      dispatch(setAuth({}));
    }
    if (!isLoading && isSuccess) {
      const payload = {
        ...userProfileData,
        token: localStorage.getItem("token"),
      };
      dispatch(setAuth(payload));
    }
  }, [isLoading, isSuccess, userProfileData, dispatch, isError]);

  return { userProfileData, isLoading, isSuccess, isError, error };
};

export default useUserProfile;
