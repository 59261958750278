import AdditionalNotesForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/AdditionalNotesForm";
import AllergiesForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/AllergiesForm";
import BarriersForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/BarriersForm";
import GoalsForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/GoalsForm";
import MedicationsForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/MedicationsForm";
import MonitoringForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/MonitoringForm";
import PatientHistoryForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/PatientHistoryForm";
import PreventiveCareForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/PreventiveCareForm";
import PsychoNeuroTestingForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/PsychoNeuroTestingForm";
import RiskClassificationForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/RiskClassificationForm";
import SelfManagementForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/SelfManagementForm";
import SymptomsForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/SymptomsForm";
import { setIsModalOpen } from "@/store/features/ccm/ccmFormSlice";
import { Button, Collapse, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ContainerModal from "./multiStepForm/ContainerModal";
import { StyledDivContainer, StyledMainTableContent } from "./styles";
import { useEffect } from "react";
import { setModule } from "@/store/features/timeTracking/timeTrackerSlice";
const CCMCarePlan = () => {
  const dispatch = useDispatch();
  const handleEditClick = () => {
    dispatch(setIsModalOpen(true));
  };
  const selectedCarePlan = useSelector(
    (state) => state.patientDetails.carePlan
  );
  useEffect(() => {
    if (selectedCarePlan !== "ccm") return;
    dispatch(
      setModule({
        moduleName: "CCM",
        cptCode: 17,
      })
    );
  }, [selectedCarePlan, dispatch]);
  if (selectedCarePlan !== "ccm") return null;
  return (
    <>
      <StyledMainTableContent>
        <StyledDivContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "rgba(0,0,0,0.05)",
              padding: "8px",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <h2>Initial Patient Care Plan</h2>
            <Button
              type="primary"
              style={{ padding: "3px 23px 3px 23px", borderRadius: "16px" }}
              onClick={handleEditClick}
            >
              Edit
            </Button>
          </div>

          {/* Collpase Content */}

          <div>
            <CollapseForm />
          </div>
        </StyledDivContainer>

        {/* Left Side Table.... */}

        <StyledDivContainer>
          {/* Main Heading */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "rgba(0,0,0,0.05)",
              padding: "8px",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <h2>Current Monthly Update</h2>
            <DatePicker mode="month" />
          </div>

          {/* Text */}

          {/* Updated Collpase Content */}

          <div>
            <UpdatedCollapseForm />
          </div>
        </StyledDivContainer>
      </StyledMainTableContent>
      <ContainerModal />
    </>
  );
};

export default CCMCarePlan;

const CollapseForm = () => {
  const items = [
    {
      key: "1",
      label: <span style={{ fontWeight: "bold" }}>Patient History</span>,
      children: <PatientHistoryForm />,
    },
    {
      key: "2",
      label: <span style={{ fontWeight: "bold" }}>Goals</span>,
      children: <GoalsForm />,
    },
    {
      key: "3",
      label: <span style={{ fontWeight: "bold" }}>Barriers</span>,
      children: <BarriersForm />,
    },
    {
      key: "4",
      label: <span style={{ fontWeight: "bold" }}>Symptoms</span>,
      children: <SymptomsForm />,
    },
    {
      key: "5",
      label: <span style={{ fontWeight: "bold" }}>Allergies</span>,
      children: <AllergiesForm />,
    },
    {
      key: "6",
      label: <span style={{ fontWeight: "bold" }}>Medications</span>,
      children: <MedicationsForm />,
    },
    {
      key: "7",
      label: <span style={{ fontWeight: "bold" }}>Monitoring</span>,
      children: <MonitoringForm />,
    },
    {
      key: "8",
      label: <span style={{ fontWeight: "bold" }}>Preventive Care</span>,
      children: <PreventiveCareForm />,
    },
    {
      key: "9",
      label: (
        <span style={{ fontWeight: "bold" }}>
          Psychological and Neuro-Psychological Testing{" "}
        </span>
      ),
      children: <PsychoNeuroTestingForm />,
    },
    {
      key: "10",
      label: <span style={{ fontWeight: "bold" }}>Self Management</span>,
      children: <SelfManagementForm />,
    },
    {
      key: "11",
      label: <span style={{ fontWeight: "bold" }}>Additional Notes</span>,
      children: <AdditionalNotesForm />,
    },
    {
      key: "12",
      label: <span style={{ fontWeight: "bold" }}>Risk Classification</span>,
      children: <RiskClassificationForm />,
    },
  ];

  return (
    <Collapse
      accordion
      items={items}
      style={{
        padding: "4px 9px 4px 9px",
        backgroundColor: "white",
        fontSize: "17px",
      }}
    />
  );
};

const UpdatedCollapseForm = () => {
  const updatedItems = [
    {
      key: "1",
      label: <span style={{ fontWeight: "bold" }}>Patient History</span>,
      children: <PatientHistoryForm />,
    },
    {
      key: "2",
      label: <span style={{ fontWeight: "bold" }}>Goals</span>,
      children: <GoalsForm />,
    },
    {
      key: "3",
      label: <span style={{ fontWeight: "bold" }}>Barriers</span>,
      children: <BarriersForm />,
    },
    {
      key: "4",
      label: <span style={{ fontWeight: "bold" }}>Symptoms</span>,
      children: <SymptomsForm />,
    },
    {
      key: "5",
      label: <span style={{ fontWeight: "bold" }}>Allergies</span>,
      children: <AllergiesForm />,
    },
    {
      key: "6",
      label: <span style={{ fontWeight: "bold" }}>Medications</span>,
      children: <MedicationsForm />,
    },
    {
      key: "7",
      label: <span style={{ fontWeight: "bold" }}>Monitoring</span>,
      children: <MonitoringForm />,
    },
    {
      key: "8",
      label: <span style={{ fontWeight: "bold" }}>Preventive Care</span>,
      children: <PreventiveCareForm />,
    },
    {
      key: "9",
      label: (
        <span style={{ fontWeight: "bold" }}>
          Psychological and Neuro-Psychological Testing
        </span>
      ),
      children: <PsychoNeuroTestingForm />,
    },
    {
      key: "10",
      label: <span style={{ fontWeight: "bold" }}>Self Management</span>,
      children: <SelfManagementForm />,
    },
    {
      key: "11",
      label: <span style={{ fontWeight: "bold" }}>Additional Notes</span>,
      children: <AdditionalNotesForm />,
    },
    {
      key: "12",
      label: <span style={{ fontWeight: "bold" }}>Risk Classification</span>,
      children: <RiskClassificationForm />,
    },
  ];

  return (
    <Collapse
      accordion
      items={updatedItems}
      style={{
        padding: "4px 9px 4px 9px",
        backgroundColor: "white",
        fontSize: "17px",
      }}
    />
  );
};
