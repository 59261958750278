import { FilterIcon } from "@/assets/icons";
import { setSearchTerm } from "@/store/features/dashboard/dashboardSlice";
import debounce from "@/utils/debounce";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 48px;
  padding: 8px 16px;
  max-width: 400px;
`;

const StyledInput = styled(Input)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 16px;
  padding: 4px 20px;

  &::placeholder {
    color: #888;
  }

  &:focus {
    box-shadow: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #888;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #ddd;
  margin: 0 12px;
`;

const AISearchComponent = ({ onSearch = () => {}, onFilter, ...props }) => {
  const dispatch = useDispatch();
  // Define the debounced handleChange function
  const handleChange = debounce((e) => {
    dispatch(setSearchTerm(e.target.value));
  }, 500);

  return (
    <SearchContainer>
      <IconWrapper>
        <SearchOutlined style={{ fontSize: "20px", color: "#76767B" }} />
      </IconWrapper>
      <StyledInput
        placeholder="Search with AI"
        onChange={handleChange}
        onPressEnter={(e) => onSearch(e.target.value)}
        {...props}
      />
      <Divider />
      <IconWrapper
        onClick={() => onFilter(true)}
        style={{ fontSize: "20px", cursor: "pointer" }}
      >
        <FilterIcon />
      </IconWrapper>
    </SearchContainer>
  );
};

export default AISearchComponent;
