import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  filters: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setSearchTerm, setFilters } = dashboardSlice.actions;
export default dashboardSlice.reducer;
