/**
 * External dependencies
 */
import { Badge, Dropdown, Typography } from "antd";

/**
 * Internal dependencies
 */
import {
  LogoutIcon,
  MessageIcon,
  NotificationIcon,
  ProfileIcon,
  SettingsIcon,
} from "../../../assets/icons";
import {
  AlertContainer,
  IconContainer,
  StyledAvatar,
  StyledHeader,
} from "./style";
import { useLogout } from "@/hooks/auth/useLogout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GLOBAL_SETTINGS } from "@/routes/routeDefinitions";
import Stopwatch from "@/Pages/Patients/timeTracking/StopWatch";

/**
 * CustomHeader component for the application.
 * Displays a header with message, notification, and settings icons,
 * as well as a user avatar with a dropdown menu.
 *
 * @returns {React.Component} A header component
 */
const CustomHeader = () => {
  const { handleLogout } = useLogout();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split("/")[1];
  const menuItems = [
    {
      key: "profile",
      icon: <ProfileIcon />,
      label: "Profile",
    },
    {
      key: "settings",
      icon: <SettingsIcon />,
      label: "Settings",
      onClick: () => navigate(GLOBAL_SETTINGS),
    },
    {
      key: "logout",
      icon: <LogoutIcon />,
      label: "Logout",
      onClick: handleLogout,
    },
  ];

  return (
    <StyledHeader>
      <Typography.Title level={4}>{routeToMenuMap[path]}</Typography.Title>
      <AlertContainer>
        <Stopwatch />
        <IconContainer>
          <Badge count={5} size="small">
            <Link to="/messages">
              <MessageIcon />
            </Link>
          </Badge>
        </IconContainer>
        <IconContainer>
          <Badge count={2} size="small">
            <Link to="/notifications">
              <NotificationIcon />
            </Link>
          </Badge>
        </IconContainer>
        <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
          <StyledAvatar src="https://joeschmoe.io/api/v1/random" />
        </Dropdown>
      </AlertContainer>
    </StyledHeader>
  );
};

export default CustomHeader;

const routeToMenuMap = {
  devices: "Devices",
  patients: "Patients",
  messages: "Messages",
  settings: "Settings",
  reports: "Reports",
  dashboard: "Dashboard",
};
