import { List, Typography, Space } from "antd";
import {
  MedicineBoxOutlined,
  ExperimentOutlined,
  MessageOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const { Title } = Typography;

const AppointmentHistoryContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
`;

const ViewAllLink = styled.a`
  float: right;
  font-size: 14px;
  color: #1890ff;
`;

const StyledList = styled(List)`
  .ant-list-item {
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
  }
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

const AppointmentType = styled.div`
  font-weight: 500;
`;

const AppointmentDate = styled.div`
  color: #8c8c8c;
  font-size: 12px;
`;

const appointmentData = [
  {
    type: "Medical Checkup",
    date: "March 14 · 10:00 am",
    icon: <MedicineBoxOutlined />,
  },
  {
    type: "Screeing",
    date: "April 10 · 2:40 pm",
    icon: <ExperimentOutlined />,
  },
  {
    type: "Chat Consultation",
    date: "May 22 · 11:30 am",
    icon: <MessageOutlined />,
  },
  {
    type: "Video call Consultation",
    date: "Jun 14 · 10:30 am",
    icon: <VideoCameraOutlined />,
  },
];

const AppointmentHistory = () => {
  return (
    <AppointmentHistoryContainer>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <div>
          <Title level={5} style={{ margin: 0, display: "inline-block" }}>
            Upcoming Appointment History
          </Title>
          <ViewAllLink href="#">View all</ViewAllLink>
        </div>
        <StyledList
          itemLayout="horizontal"
          dataSource={appointmentData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<IconWrapper>{item.icon}</IconWrapper>}
                title={<AppointmentType>{item.type}</AppointmentType>}
                description={<AppointmentDate>{item.date}</AppointmentDate>}
              />
            </List.Item>
          )}
        />
      </Space>
    </AppointmentHistoryContainer>
  );
};

export default AppointmentHistory;
