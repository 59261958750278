import { useAddPatientNoteMutation } from "@/store/features/patientNotes/patientNoteApiSlice";
import { Button, Form, Input, message, Select } from "antd";
const { TextArea } = Input;
const { Option } = Select;

const NoteForm = ({ patientId, onSuccess = () => {} }) => {
  const [form] = Form.useForm();
  const [addNote, { isLoading }] = useAddPatientNoteMutation();

  const handleSubmit = async (values) => {
    try {
      await addNote({ ...values, patientId });
      form.resetFields();
      onSuccess();
    } catch (error) {
      console.error(error);
      message.error("Failed to add note");
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        name="notes"
        rules={[{ required: true, message: "Please enter notes" }]}
      >
        <TextArea rows={4} placeholder="Enter notes" />
      </Form.Item>
      <Form.Item
        name="activityFor"
        rules={[{ required: true, message: "Please select activity" }]}
      >
        <Select placeholder="Select activity">
          <Option value="RPM">RPM</Option>
          <Option value="CCM">CCM</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NoteForm;
