import { Row, Col, Card } from "antd";
import {
  CalendarOutlined,
  FileTextOutlined,
  LineChartOutlined,
  DollarOutlined
} from "@ant-design/icons";
import styled from "styled-components";

const StatsCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`;

const StatValue = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-right: 8px;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #8c8c8c;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const statsData = [
  {
    label: "Appointments",
    value: "30/65",
    color: "#52c41a",
    icon: CalendarOutlined
  },
  {
    label: "Consultations",
    value: "20/75",
    color: "#f5222d",
    icon: FileTextOutlined
  },
  {
    label: "Operations",
    value: "12/30",
    color: "#faad14",
    icon: LineChartOutlined
  },
  { label: "Earning", value: "$530", color: "#1890ff", icon: DollarOutlined }
];

const StatsCardSection = () => (
  <Row gutter={[16]}>
    {statsData.map((stat, index) => (
      <Col flex="25%" key={index}>
        <StatsCard
          bodyStyle={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>
            <StatValue style={{ color: stat.color }}>{stat.value}</StatValue>
            <StatLabel>{stat.label}</StatLabel>
          </div>
          <IconWrapper>
            <stat.icon style={{ fontSize: "24px", color: stat.color }} />
          </IconWrapper>
        </StatsCard>
      </Col>
    ))}
  </Row>
);

export default StatsCardSection;
