/**
 * External dependencies
 */

import useUserProfile from "@/hooks/auth/useUserProfile";
import { LOGIN_PATH } from "@/routes/routeDefinitions";
import WithWebSocketConnection from "@/routes/withWebSocketConnection";
import { Spin } from "antd";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { isLoading, isSuccess } = useUserProfile();
  const location = useLocation();
  if (isLoading) {
    return <Spin size="large" spinning={true} fullscreen />;
  } else if (isSuccess) {
    // return <WithWebSocketConnection>{children}</WithWebSocketConnection>;
    return children;
  } else {
    const redirectUrl = `${location.pathname}${location.search}`;
    return <Navigate to={LOGIN_PATH} state={{ redirectUrl }} />;
  }
};

export default ProtectedRoute;
