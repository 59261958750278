import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Input,
  Space,
  Table,
  Typography,
  Card,
  Tag,
} from "antd";
import styled from "styled-components";

const { Title, Text } = Typography;

const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  padding: 4px 12px;
  border-radius: 16px;
`;

const CloseButton = styled(CloseOutlined)`
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
  }
`;

const NotificationTag = styled(Tag)`
  border-radius: 12px;
  padding: 4px 8px;
  font-weight: 500;
`;

const NotificationTable = () => {
  const columns = [
    { title: "Vitals", dataIndex: "vital", key: "vitals" },
    { title: "Values", dataIndex: "values", key: "values" },
    {
      title: "Notifications",
      dataIndex: "notification",
      key: "notification",
      render: (text, record) => (
        <NotificationTag color={record.notificationColor}>
          {text}
        </NotificationTag>
      ),
    },
    { title: "Recorded Date", dataIndex: "date", key: "date" },
    {
      title: "Case Handled",
      dataIndex: "case",
      key: "case",
      render: () => <Checkbox />,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: () => <Input style={{ width: "100%" }} />,
    },
  ];

  const data = [
    {
      key: "1",
      vital: "Temperature",
      values: "104",
      notification: "Critical",
      notificationColor: "error",
      date: "June 09 2023",
    },
    {
      key: "2",
      vital: "Glucose",
      values: "304",
      notification: "Critical",
      notificationColor: "error",
      date: "April 04 2023",
    },
    {
      key: "3",
      vital: "Vitamin-C",
      values: "134",
      notification: "Warning",
      notificationColor: "warning",
      date: "May 05 2023",
    },
    // ... add more data as needed
  ];

  return (
    <StyledCard>
      <HeaderContainer>
        <Title level={4}>Notifications - Ritika Negi</Title>
        <TimerContainer>
          <Text>Timer: 8 Sec</Text>
          <CloseButton />
        </TimerContainer>
      </HeaderContainer>

      <StyledTable
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 280 }}
      />

      <Space
        style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}
      >
        <Button>Cancel</Button>
        <Button type="primary">Save</Button>
      </Space>
    </StyledCard>
  );
};

export default NotificationTable;
