import { Flex, Spin } from "antd";
import TypewriterEffect from "@/components/shared/TypeWriterEffect";

const LoadingComponent = ({ text }) => {
  return (
    <Flex vertical align="center" justify="center" gap={"large"}>
      <Spin />
      <TypewriterEffect speed={11}>{text}</TypewriterEffect>
    </Flex>
  );
};

export default LoadingComponent;
