import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  token: localStorage.getItem("token") || null,
  username: null,
  email: null,
  phoneNumber: null,
  role: null,
  permissions: null,
  name: null,
  address: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const { token } = action.payload;
      const payload = { ...action.payload };
      payload.isAuthenticated = !!token;
      Object.assign(state, payload);
      // Persist token to localStorage
      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.removeItem("token");
      }
    },
    clearAuth: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.roles = null;
      state.permissions = null;
      state.user = null;
      localStorage.removeItem("token");
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { setAuth, clearAuth, updateUser } = authSlice.actions;
export default authSlice.reducer;
