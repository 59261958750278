import { Card, Radio } from "antd";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

const StyledCard = styled(Card)`
  .ant-card-head-title {
    font-size: 18px;
    font-weight: bold;
  }
`;

const ChartContainer = styled.div`
  height: 300px;
  width: 100%;
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const LegendColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
`;

const dummyData = [
  { month: "Jan", thisWeek: 2400, thisMonth: 2000, yearly: 1800 },
  { month: "Feb", thisWeek: 1398, thisMonth: 2210, yearly: 2100 },
  { month: "Mar", thisWeek: 9800, thisMonth: 9000, yearly: 8500 },
  { month: "Apr", thisWeek: 3908, thisMonth: 4000, yearly: 3800 },
  { month: "May", thisWeek: 4800, thisMonth: 4500, yearly: 4300 },
  { month: "Jun", thisWeek: 3800, thisMonth: 3500, yearly: 3300 },
  { month: "Jul", thisWeek: 4300, thisMonth: 4000, yearly: 3800 },
  { month: "Aug", thisWeek: 5300, thisMonth: 5000, yearly: 4800 },
  { month: "Sep", thisWeek: 4300, thisMonth: 4200, yearly: 4000 },
  { month: "Oct", thisWeek: 4300, thisMonth: 4100, yearly: 3900 },
  { month: "Nov", thisWeek: 3300, thisMonth: 3000, yearly: 2800 },
  { month: "Dec", thisWeek: 2300, thisMonth: 2000, yearly: 1800 },
];

const colorMap = {
  thisWeek: {
    stroke: "#4E79A7",
    fill: "#4E79A7",
  },
  thisMonth: {
    stroke: "#59A14F",
    fill: "#59A14F",
  },
  yearly: {
    stroke: "#EDC948",
    fill: "#EDC948",
  },
};

const DoctorRevenueGraph = () => {
  const [timeRange, setTimeRange] = React.useState("thisMonth");

  const handleRangeChange = (e) => {
    setTimeRange(e.target.value);
  };

  return (
    <StyledCard
      title="Revenue"
      extra={
        <Radio.Group value={timeRange} onChange={handleRangeChange}>
          <Radio.Button value="thisWeek">This Week</Radio.Button>
          <Radio.Button value="thisMonth">This Month</Radio.Button>
          <Radio.Button value="yearly">Yearly</Radio.Button>
        </Radio.Group>
      }
    >
      <ChartContainer>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            data={dummyData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey={timeRange}
              stroke={colorMap[timeRange].stroke}
              fill={[colorMap[timeRange].fill]}
              fillOpacity={0.3}
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </ChartContainer>
    </StyledCard>
  );
};

export default DoctorRevenueGraph;
