import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./features/api/apiSlice";
import notificationSlice from "./features/notification/notificationSlice";
import authSlice from "./features/auth/authSlice";
import errorMessageSlice from "./features/errorMessage/errorMessageSlice";
import addPatientSlice from "./features/patient/addPatientSlice";
import ccmFormSlice from "@/store/features/ccm/ccmFormSlice";
import patientDetailsSlice from "@/store/features/patientDetails/patientDetailsSlice";
import dashboardSlice from "@/store/features/dashboard/dashboardSlice";
import timeTrackerSlice from "@/store/features/timeTracking/timeTrackerSlice";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    notification: notificationSlice,
    errorMessage: errorMessageSlice,
    auth: authSlice,
    addPatient: addPatientSlice,
    ccmForm: ccmFormSlice,
    patientDetails: patientDetailsSlice,
    dashboard: dashboardSlice,
    timeTracker: timeTrackerSlice,
  },
  devTools: import.meta.env.DEV,
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});
