import NoteForm from "@/Pages/Patients/patientDetails/details/forms/NotesForm";
import { Flex, Modal } from "antd";
import { useState } from "react";

export default function NoteAndTime(props) {
  const { time, note, patientId } = props;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Flex vertical gap="small">
      <div
        onClick={() => setModalOpen(true)}
        style={{
          fontSize: "14px",
          fontWeight: 400,
          padding: "6px 14px",
          borderRadius: "30px",
          backgroundColor: "#F1EFEE",
          width: "95%",
        }}
      >
        {note}
      </div>
      <Flex
        gap="small"
        align="center"
        style={{ fontSize: "14px", fontWeight: 400, color: "#242235" }}
      >
        <div
          style={{
            height: "12px",
            width: "12px",
            borderRadius: "50%",
            backgroundColor: "#63A3B2",
          }}
        />
        {time}
      </Flex>
      <Modal
        title="Add a Quick Note"
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <NoteForm patientId={patientId} onSuccess={() => setModalOpen(false)} />
      </Modal>
    </Flex>
  );
}
