import { useDispatch, useSelector } from "react-redux";
import BloodPressure from "./BloodPressure";
import Glucose from "./Glucose";
import Pulse from "./Pulse";
import Spo2 from "./Spo2";
import Temperature from "./Temperature";
import Weight from "./Weight";

import { useGetRPMByHealthIdQuery } from "@/store/features/patientDetails/patientDetailsApiSlice";
import { Spin } from "antd";
import { StyledDivContainer, StyledMainDivContainer } from "./styles";
import { useEffect } from "react";
import { setModule } from "@/store/features/timeTracking/timeTrackerSlice";

const RPMCarePlan = () => {
  const { carePlan: selectedCarePlan, currentPatientHealthId } = useSelector(
    (state) => state.patientDetails
  );

  const { data: rpmData = [], isLoading: isRPMLoading } =
    useGetRPMByHealthIdQuery(
      {
        pageNumber: 1,
        pageSize: 10,
        healthConditionId: currentPatientHealthId,
      },
      { skip: !currentPatientHealthId }
    );

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedCarePlan !== "rpm") return;
    dispatch(
      setModule({
        moduleName: "RPM",
        cptCode: 11,
      })
    );
  }, [dispatch, selectedCarePlan]);

  if (selectedCarePlan !== "rpm") return null;
  return (
    <Spin spinning={isRPMLoading}>
      <StyledMainDivContainer>
        <StyledDivContainer>
          <BloodPressure rpmData={rpmData} />
          <Pulse rpmData={rpmData} />
        </StyledDivContainer>
        <StyledDivContainer>
          <Weight rpmData={rpmData} />
          <Temperature rpmData={rpmData} />
        </StyledDivContainer>
        <StyledDivContainer>
          <Spo2 rpmData={rpmData} />
          <Glucose rpmData={rpmData} />
        </StyledDivContainer>
      </StyledMainDivContainer>
    </Spin>
  );
};

export default RPMCarePlan;
