import {
  BloodPressureIcon,
  GlucoseIcon,
  HeartRateIcon,
  SpO2Icon,
  TasksIcon,
  TemperatureIcon,
  WeightIcon,
  PendingTaskIcon,
  NotificationBellIcon,
  MessageBubbleIcon,
} from "@/assets/icons";
import { Card, Col, Flex, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import PatientInfo from "../../../../components/shared/PatientInfo";
import PriorityTag from "./PriorityTag";
import StatCounter from "./StatCounter";
import NoteAndTime from "../../../../components/shared/NoteAndTime";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)`
  margin-bottom: 8px;
  border-radius: 12px;
  .ant-card-body {
    padding: 16px;
  }
`;

const PatientCard = ({ patient, loading }) => {
  const [patientVitals, setPatientVitals] = useState([]);
  const [patientAlerts, setPatientAlerts] = useState([]);
  const [patientData, setPatientData] = useState({});
  useEffect(() => {
    const vitals = [
      {
        icon: <HeartRateIcon />,
        value: patient?.heartRate || "N/A",
      },
      {
        icon: <BloodPressureIcon />,
        value: patient?.systolicPressure
          ? `${patient?.systolicPressure}/${patient?.diastolicPressure}`
          : "N/A",
      },
      {
        icon: <GlucoseIcon />,
        value: patient.bloodGlucose || "N/A",
      },
      {
        icon: <SpO2Icon />,
        value: patient.spO2 || "N/A",
      },
      {
        icon: <WeightIcon />,
        value: patient.weight || "N/A",
      },
      {
        icon: <TemperatureIcon />,
        value: patient.temperature || "N/A",
      },
    ];
    const alerts = [
      {
        icon: <TasksIcon />,
        value: patient.notesCount || "N/A",
      },
      {
        icon: <PendingTaskIcon />,
        value: patient.pendingTaskCount || "N/A",
      },
      {
        icon: <NotificationBellIcon />,
        value: patient.notifications || "N/A",
      },
      {
        icon: <MessageBubbleIcon />,
        value: patient.messages || "N/A",
      },
    ];

    const patientName = patient?.patientName;
    // const patientName = patient?.middleName
    //   ? `${patient?.firstName} ${patient?.middleName} ${patient?.lastName}`
    //   : `${patient?.firstName} ${patient?.lastName}`;
    setPatientAlerts(alerts);
    setPatientVitals(vitals);
    setPatientData({
      vitals: vitals,
      alerts: alerts,
      name: patientName,
      photoUrl: patient?.photoUrl,
      priority: patient?.priority,
      room: patient?.room || "N/A",
    });
  }, [patient]);

  return (
    <StyledCard>
      <Skeleton loading={loading} active avatar paragraph={{ rows: 1 }}>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={12} md={12} lg={6} xl={5} xxl={4}>
            <Link to={`/patients/${patient.patientId}/details`}>
              <PatientInfo
                name={patientData.name}
                room={patientData.room}
                photoUrl={patientData.photoUrl}
              />
            </Link>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={3} xxl={2}>
            <PriorityTag priority={patientData.priority || "moderate"} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={7} xxl={6}>
            <Flex gap="middle" align="center" justify="space-around" wrap>
              {patientVitals.map((vital, index) => (
                <StatCounter
                  key={index}
                  icon={vital.icon}
                  value={vital.value}
                />
              ))}
            </Flex>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={3}>
            <Flex gap="middle" align="center" justify="space-around" wrap>
              {patientAlerts.map((vital, index) => (
                <StatCounter
                  key={index}
                  icon={vital.icon}
                  value={vital.value}
                />
              ))}
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={7}>
            <NoteAndTime
              patientId={patient.patientId}
              time="N/A"
              note={patient?.lastCreatedNote ? patient?.lastCreatedNote : "N/A"}
            />
          </Col>
        </Row>
      </Skeleton>
    </StyledCard>
  );
};

export default PatientCard;
