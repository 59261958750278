import { Button, Typography } from "antd";
import { Container, StyledIconsDiv } from "./style";

import AISearchComponent from "@/Pages/Patients/dashboard/components/Searchbar";
import FilterForm from "@/Pages/Patients/dashboard/filter";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(false);

  return (
    <Container>
      {/* <MonitoringSelector options={monitoringOptions} /> */}
      <Typography.Title level={3}>Patient List</Typography.Title>
      <StyledIconsDiv>
        {/* Search Bar Component */}

        <AISearchComponent onFilter={setFormOpen} />

        {/* Plus Button */}

        <Button
          type="primary"
          shape="circle"
          icon={
            <PlusOutlined style={{ fontSize: "20px", fontWeight: "bolder" }} />
          }
          onClick={() => navigate("/patients/add")}
        />
      </StyledIconsDiv>
      <FilterForm open={formOpen} setOpen={setFormOpen} />
    </Container>
  );
};

export default Header;
