import BackButton from "@/components/ui/BackButton";
import TimeTrackingModal from "@/Pages/Patients/timeTracking/TimeTrackingModal";
import { useGetPatientByIdQuery } from "@/store/features/patient/patientApiSlice";
import calculateAge from "@/utils/getAgeFromDOB";
import getFullName from "@/utils/getFullName";
import { EditOutlined } from "@ant-design/icons";
import { Button, Skeleton, Typography } from "antd";
import { useParams } from "react-router-dom";
import { StyledDivContainer } from "./Style";

const PatientInfo = () => {
  const { patientId } = useParams();
  const { data, isLoading, isSuccess } = useGetPatientByIdQuery(patientId);
  const age = data?.dob && calculateAge(data?.dob)?.formatted;
  const name = getFullName(data?.firstName, data?.middleName, data?.lastName);
  const gender = data?.gender;

  return (
    <StyledDivContainer className="upper">
      <BackButton />

      <Skeleton loading={isLoading} active paragraph={false}>
        <Typography.Title level={3}>
          {isSuccess && `${name} (${age}/${gender})`}
        </Typography.Title>
      </Skeleton>
      <Typography.Title level={5}>
        <Button
          icon={<EditOutlined size={30} />}
          style={{ marginLeft: "10px" }}
        />
      </Typography.Title>
      <TimeTrackingModal />
    </StyledDivContainer>
  );
};

export default PatientInfo;
