import styled from "styled-components";
import { Typography, List } from "antd";

const { Text } = Typography;
export const Container = styled.div`
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  height: 100%;
  width: calc(100% + 2rem);
`;

export const Sidebar = styled.div`
  width: 300px;
  border-right: 1px solid #f0f0f0;
  background: #fff;
  margin-left: -30px;
  height: calc(100vh - 70px);
  overflow-y: auto;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fff;
`;

export const ChatHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const MessageList = styled.div`
  padding: 24px;
  height: calc(100vh - 210px);
`;

export const MessageBubble = styled.div`
  background-color: ${(props) => (props.isUser ? "#1890ff" : "#f0f0f0")};
  color: ${(props) => (props.isUser ? "#fff" : "rgba(0, 0, 0, 0.85)")};
  border-radius: 16px;
  padding: 8px 12px;
  margin-bottom: 8px;
  max-width: fit-content;
  margin-left: ${(props) => props.isUser && "auto"};
  text-align: ${(props) => props.isUser && "right"};
`;

export const MessageInputWrapper = styled.div`
  padding: 16px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledList = styled(List)`
  .ant-list-item {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`;

export const LastMessageTime = styled(Text)`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`;
