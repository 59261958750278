import Header from "./header";
import PatientDashboard from "./dashboard";
import { Container } from "./styles";
const Patients = () => {
  return (
    <Container>
      <Header />
      <PatientDashboard />
    </Container>
  );
};

export default Patients;
