import useGetAllPatients from "@/hooks/dashboard/useGetAllPatients";
import usePubNub from "@/hooks/pubnub/usePubnub";
import PatientList from "@/Pages/Messages/PatientList";
import {
  CloseOutlined,
  SearchOutlined,
  SendOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Flex,
  Input,
  message,
  Popover,
  Typography,
  Upload,
} from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CallInterface from "./CallInterface";
import {
  ChatContainer,
  ChatHeader,
  Container,
  MessageBubble,
  MessageInputWrapper,
  MessageList,
  Sidebar,
} from "./Style";
dayjs.extend(relativeTime);

const { Text } = Typography;

const MessagingUI = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [fileList, setFileList] = useState([]);
  const userName = useSelector((state) => state.auth.username) || "";
  const onSearchOpen = () => {
    console.log("search opened");
  };

  const popOverContent = (
    <Flex gap={"small"}>
      <Input
        value={searchText}
        placeholder="search within chat."
        onChange={(e) => setSearchText(e.target.value)}
        prefix={<SearchOutlined />}
      />
      <Button icon={<CloseOutlined />} onClick={() => setSearchText("")} />
    </Flex>
  );

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  // --------------------------------------------------------------

  const { receiver } = useParams();
  //   const [fileList, setFileList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 20;

  const {
    isLoading: isLoadingPatients,
    patientList,
    totalCount,
    error: patientsError,
  } = useGetAllPatients({ pageNumber, pageSize });
  const [infinitePatientList, setInfinitePatientList] = useState([]);
  useEffect(() => {
    setInfinitePatientList((prev) => [...prev, ...patientList]);
  }, [patientList]);
  const {
    messages,
    sendMessage,
    createChannel,
    subscribeToChannel,
    fetchHistory,
    sendTypingIndicator,
    // markMessageAsRead,
    uploadFile,
    error: pubNubError,
  } = usePubNub();

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (receiver) {
      const person = patientList.find(
        (p) => p.patientId.toString() === receiver
      );
      if (person) {
        handlePersonSelect(person);
      }
    }
  }, [receiver, patientList]);

  useEffect(() => {
    if (pubNubError) {
      message.error("An error occurred while sending/receiving messages.");
    }
  }, [pubNubError]);

  useEffect(() => {
    if (patientsError) {
      message.error("An error occurred while fetching patients.");
    }
  }, [patientsError]);

  const handlePersonSelect = async (person) => {
    setSelectedPerson(person);
    const channelName = createChannel(person.firstName + person.lastName);
    subscribeToChannel(channelName);
    await fetchHistory();
  };

  const handleSend = async () => {
    if (inputValue.trim() && selectedPerson) {
      await sendMessage(inputValue);
      setInputValue("");
    }
  };

  const handleFileUpload = async (file) => {
    try {
      const url = await uploadFile(file);
      await sendMessage(url, "file");
    } catch (error) {
      message.error("Failed to upload file.", error);
    }
  };

  const loadMorePatients = () => {
    if (patientList.length < totalCount) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);
  useEffect(() => {
    console.log("Messages: ", messages);
  }, [messages]);
  //-------------------------------------------------------------
  return (
    <Container>
      <Sidebar>
        <PatientList
          patientList={infinitePatientList}
          totalCount={totalCount}
          isLoadingPatients={isLoadingPatients}
          loadMorePatients={loadMorePatients}
          handlePersonSelect={handlePersonSelect}
        />
      </Sidebar>
      <ChatContainer>
        {selectedPerson ? (
          <Fragment>
            <ChatHeader>
              {/*left section of the header*/}
              <Flex align="center">
                <Avatar
                  style={{ marginRight: 16 }}
                  src={selectedPerson?.photoUrl}
                  alt={selectedPerson?.firstName}
                ></Avatar>
                <Text strong>{selectedPerson.firstName}</Text>
              </Flex>

              {/*right section of the header*/}
              <Flex gap="middle">
                <CallInterface currentUserId={"1"} contactId={"2"} />

                <Popover title={popOverContent} placement="bottomLeft">
                  <Button
                    type="text"
                    icon={<SearchOutlined />}
                    content={popOverContent}
                    onClick={onSearchOpen}
                  />
                </Popover>
              </Flex>
            </ChatHeader>
            <MessageList>
              {messages.map((message) => (
                <MessageBubble
                  key={message.timestamp}
                  isUser={message.sender === userName}
                >
                  {message.content}
                </MessageBubble>
              ))}
            </MessageList>
            <MessageInputWrapper>
              <Upload {...props}>
                <Button type="text" icon={<UploadOutlined />} />
              </Upload>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={handleSend}
                suffix={
                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={handleSend}
                  />
                }
                placeholder="Type your message here..."
              />
            </MessageInputWrapper>
          </Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Text>Select a person to start chatting</Text>
          </div>
        )}
      </ChatContainer>
    </Container>
  );
};

export default MessagingUI;
