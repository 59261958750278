import { Button, Flex, Form, Typography } from "antd";
import LocationManagement from "./Component/LocationManagement";
import PhoneNumber from "./Component/PhoneNumber";
import SmsReminder from "./Component/SmsReminder";
import { StyledDivContainer, StyledMainContainer } from "./Style";

export default function GlobalSetting() {
  const [form] = Form.useForm();
  return (
    <StyledMainContainer>
      <Typography.Title level={2}>Global Settings</Typography.Title>
      <StyledDivContainer>
        <Form form={form}>
          <StyledDivContainer>
            <PhoneNumber />
            <SmsReminder />
            <Flex justify="end">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Flex>
          </StyledDivContainer>
        </Form>
        <StyledDivContainer>
          <LocationManagement />
        </StyledDivContainer>
      </StyledDivContainer>
    </StyledMainContainer>
  );
}
