import { RouterProvider } from "react-router-dom";
import "./App.css";
import appRouter from "./routes/router";
import NotificationSnackbar from "./components/ui/notification/NotificationSnackbar";
import { ConfigProvider } from "antd";
import theme from "./constants/theme.js";
function App() {
  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={appRouter} />
      <NotificationSnackbar />
    </ConfigProvider>
  );
}

export default App;
