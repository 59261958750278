import { useAddPatientTaskMutation } from "@/store/features/patientTasks/patientTaskApiSlice";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
const { Option } = Select;

const TaskForm = ({ patientId, onSuccess }) => {
  const [form] = Form.useForm();
  const [addTask, { isLoading }] = useAddPatientTaskMutation();

  const handleSubmit = async (values) => {
    try {
      await addTask({ ...values, patientId });

      form.resetFields();
      onSuccess();
    } catch (error) {
      console.error(error);
      message.error("Failed to add task");
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        name="title"
        rules={[{ required: true, message: "Please enter task title" }]}
      >
        <Input placeholder="Enter task title" />
      </Form.Item>
      <Form.Item
        name="status"
        rules={[{ required: true, message: "Please select status" }]}
      >
        <Select placeholder="Select status">
          <Option value="Pending">Pending</Option>
          <Option value="In Progress">In Progress</Option>
          <Option value="Completed">Completed</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="dueDate"
        rules={[{ required: true, message: "Please select due date" }]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TaskForm;
