import { useEffect, useState, useCallback } from "react";
import PubNub from "pubnub";
import { useSelector } from "react-redux";

const usePubNub = () => {
  const [pubnub, setPubnub] = useState(null);
  const [messages, setMessages] = useState([]);
  const [channel, setChannel] = useState(null);
  const [error, setError] = useState(null);
  const currentUser = useSelector((state) => state.auth.username) || "mohamed";
  //ok
  useEffect(() => {
    const publishKey = import.meta.env.VITE_PUBNUB_PUBLISH_KEY;
    const subscribeKey = import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY;
    const pubnubInstance = new PubNub({
      publishKey: publishKey,
      subscribeKey: subscribeKey,
      userId: currentUser,
    });

    setPubnub(pubnubInstance);
    return () => {
      pubnubInstance.unsubscribeAll();
    };
  }, [currentUser]);
  //ok
  const createChannel = useCallback(
    (otherUserId) => {
      const channelName = [currentUser, otherUserId].sort().join("-");
      setChannel(channelName);
      return channelName;
    },
    [currentUser]
  );

  const subscribeToChannel = useCallback(
    (channelName) => {
      console.log("Subscribing to Channel: ", channelName);
      if (pubnub) {
        pubnub.subscribe({ channels: [channelName] });
        pubnub.addListener({
          message: (event) => {
            setMessages((prevMessages) => [...prevMessages, event.message]);
          },
          status: (statusEvent) => {
            if (statusEvent.category === "PNNetworkDownCategory") {
              setError(
                "Network is down. Messages will be sent when connection is restored."
              );
            }
          },
        });
      }
    },
    [pubnub, channel, currentUser]
  );

  const sendMessage = useCallback(
    async (content, messageType = "text") => {
      if (pubnub && channel) {
        try {
          await pubnub.publish({
            channel: channel,
            message: {
              content,
              sender: currentUser,
              timestamp: new Date().toISOString(),
              type: messageType,
            },
          });
        } catch (e) {
          console.log(e);
          setError("Failed to send message. Please try again.");
        }
      }
    },
    [pubnub, channel, currentUser]
  );

  useEffect(() => {
    const fetch = async () => {
      if (pubnub && channel) {
        console.log({ pubnub, channel });
        try {
          const result = await pubnub.fetchMessages({
            channels: [channel],
            count: 100,
            reverse: true,
          });

          if (result.channels[channel]) {
            setMessages(result.channels[channel].map((msg) => msg.message));
          }
        } catch (e) {
          console.log(e);
          setError("Failed to fetch message history.");
        }
      }
    };
    fetch();
  }, [pubnub, channel]);

  const sendTypingIndicator = useCallback(
    (isTyping) => {
      if (pubnub && channel) {
        pubnub.signal({
          channel: channel,
          message: { isTyping, userId: currentUser },
        });
      }
    },
    [pubnub, channel, currentUser]
  );

  const markMessageAsRead = useCallback(
    (messageId) => {
      if (pubnub && channel) {
        pubnub.signal({
          channel: channel,
          message: { action: "read", messageId, userId: currentUser },
        });
      }
    },
    [pubnub, channel, currentUser]
  );

  const uploadFile = useCallback(
    async (file) => {
      if (pubnub) {
        try {
          const result = await pubnub.sendFile({
            channel: channel,
            file: file,
          });
          return result.file.url;
        } catch (err) {
          setError("Failed to upload file.");
          throw err;
        }
      }
    },
    [pubnub, channel]
  );

  return {
    pubnub,
    messages,
    createChannel,
    subscribeToChannel,
    sendMessage,
    sendTypingIndicator,
    markMessageAsRead,
    uploadFile,
    error,
  };
};

export default usePubNub;
