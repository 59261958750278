// components/TimeTrackingModal.js
import { setNotification } from "@/store/features/notification/notificationSlice";
import { useStartTimeTrackingMutation } from "@/store/features/timeTracking/timeTrackerApiSlice";
import {
  setTimeTracerId,
  startTracking,
} from "@/store/features/timeTracking/timeTrackerSlice";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const { confirm } = Modal;

const TimeTrackingModal = () => {
  const { cptCode, moduleName, patientId } = useSelector(
    (state) => state.timeTracker
  );
  const [startTimeTracking] = useStartTimeTrackingMutation();
  const dispatch = useDispatch();

  // Use useRef to track if the modal has been shown
  const hasShownModal = useRef(false);

  const handleOk = async () => {
    const startTime = new Date().toISOString();

    await startTimeTracking({
      startTime,
      moduleName: moduleName,
      patientId: Number(patientId),
      cptCodeId: Number(cptCode),
    })
      .then((response) => {
        dispatch(startTracking(startTime));
        dispatch(setTimeTracerId(response.data.id));
      })
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: "Failed to start time tracking",
          })
        );
      });
  };

  const showConfirm = () => {
    confirm({
      title: "Time Tracking",
      icon: <ExclamationCircleFilled />,
      content: "Would you like to start time tracking?",
      okText: "Yes",
      cancelText: "No",
      onOk: handleOk,
      onCancel() {
        // Do nothing if the user cancels
      },
    });
  };

  useEffect(() => {
    if (!hasShownModal.current && moduleName) {
      showConfirm();
      hasShownModal.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleName]);
  return null; // Since the modal is shown via confirm, the component doesn't render anything
};

export default TimeTrackingModal;
