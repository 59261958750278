import useGetAllPatients from "@/hooks/dashboard/useGetAllPatients";

export const useDashBoardData = () => {
  const {
    isLoading,
    patientList: patientData,
    isSuccess,
    isError
  } = useGetAllPatients({
    skip: false
  });
  const getAgeDistribution = () => {
    const ageGroups = [
      { name: "0-18", value: 0, fill: "#FF9999" },
      { name: "19-35", value: 0, fill: "#66B2FF" },
      { name: "36-50", value: 0, fill: "#99FF99" },
      { name: "51-65", value: 0, fill: "#FFCC66" },
      { name: "65+", value: 0, fill: "#FF66B2" }
    ];

    patientData.forEach((patient) => {
      const birthDate = new Date(patient.dob);
      const age = new Date().getFullYear() - birthDate.getFullYear();

      if (age <= 18) ageGroups[0].value++;
      else if (age <= 35) ageGroups[1].value++;
      else if (age <= 50) ageGroups[2].value++;
      else if (age <= 65) ageGroups[3].value++;
      else ageGroups[4].value++;
    });

    return ageGroups;
  };

  const getEthnicityDistribution = () => {
    const ethnicityCount = {};

    patientData.forEach((patient) => {
      if (patient.ethnicity) {
        ethnicityCount[patient.ethnicity] =
          (ethnicityCount[patient.ethnicity] || 0) + 1;
      }
    });

    return Object.entries(ethnicityCount).map(([name, value]) => ({
      name,
      value
    }));
  };

  const getHighRiskPatients = () => {
    return patientData
      .filter((patient) => {
        const birthDate = new Date(patient.dob);
        const age = new Date().getFullYear() - birthDate.getFullYear();

        // Define high risk criteria
        const isElderly = age >= 65;
        const noEmergencyContact = !patient.emergencyContact;
        const noConsent = !patient.patientConsentReceived;
        const hasMedicare = patient.payer === "Medicare";

        // Consider a patient high risk if they meet any of these criteria
        return isElderly || noEmergencyContact || noConsent || hasMedicare;
      })
      .map((patient) => ({
        key: patient.patientId,
        name: `${patient.firstName} ${patient.lastName}`,
        age: new Date().getFullYear() - new Date(patient.dob).getFullYear(),
        riskFactors: [
          new Date().getFullYear() - new Date(patient.dob).getFullYear() >= 65
            ? "Elderly"
            : null,
          !patient.emergencyContact ? "No Emergency Contact" : null,
          !patient.patientConsentReceived ? "No Consent" : null,
          patient.payer === "Medicare" ? "Medicare" : null
        ]
          .filter(Boolean)
          .join(", ")
      }));
  };

  const getActivityDistribution = () => {
    const activityCount = {};

    patientData.forEach((patient) => {
      if (patient.activityFor) {
        activityCount[patient.activityFor] =
          (activityCount[patient.activityFor] || 0) + 1;
      }
    });

    return Object.entries(activityCount).map(([name, value]) => ({
      name,
      value
    }));
  };

  return {
    isError,
    isSuccess,
    patientData,
    isLoading,
    getAgeDistribution,
    getEthnicityDistribution,
    getHighRiskPatients,
    getActivityDistribution
  };
};
