import { Button, Form, Input, Modal, Row, Col, Flex } from "antd";

const LocationForm = (props) => {
  const { open, setOpen, record } = props;
  const title = record ? "Update Location" : "Add Location";
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      title={title}
      footer={null}
      onCancel={() => setOpen(false)}
      centered
    >
      <Form
        form={form}
        name="locationForm"
        // onFinish={onFinish}
        // initialValues={initialValues}
        layout="vertical"
        requiredMark={false}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="displayName"
              label="Facility Name"
              rules={[
                { required: true, message: "Please enter the facility name" },
              ]}
            >
              <Input placeholder="Enter facility name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="facultyApi"
              label="Faculty API"
              rules={[
                { required: true, message: "Please enter the faculty API" },
              ]}
            >
              <Input placeholder="Enter faculty API" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="locationName"
              label="Location Name"
              rules={[
                { required: true, message: "Please enter the location name" },
              ]}
            >
              <Input placeholder="Enter location name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please enter the address" }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: "Please enter the city" }]}
            >
              <Input placeholder="Enter city" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "Please enter the state" }]}
            >
              <Input placeholder="Enter state" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="zipCode"
              label="Zip Code"
              rules={[{ required: true, message: "Please enter the zip code" }]}
            >
              <Input placeholder="Enter zip code" />
            </Form.Item>
          </Col>
        </Row>

        <Flex justify="flex-end">
          <Button type="primary" htmlType="submit">
            {record ? "Update" : "Add"}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default LocationForm;
