// store/timeTrackerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startTime: null,
  continueStopWatch: false,
  patientId: null,
  timeTrackerId: null,
  cptCode: null,
  moduleName: null,
};

const timeTrackerSlice = createSlice({
  name: "timeTracker",
  initialState,
  reducers: {
    startTracking: (state, action) => {
      state.startTime = action.payload;
      state.continueStopWatch = true;
    },
    stopTracking: (state) => {
      state.startTime = null;
      state.continueStopWatch = false;
      state.timeTrackerId = null;
    },
    setPatientId: (state, action) => {
      state.patientId = action.payload;
    },
    setTimeTracerId: (state, action) => {
      state.timeTrackerId = action.payload;
    },
    setModule: (state, action) => {
      state.moduleName = action.payload.moduleName;
      state.cptCode = action.payload.cptCode;
    },
    resetTimeTrackingInfo: (state) => {
      state.startTime = null;
      state.continueStopWatch = false;
      state.timeTrackerId = null;
      state.patientId = null;
      state.moduleName = null;
      state.cptCode = null;
    },
  },
});

export const {
  startTracking,
  stopTracking,
  setPatientId,
  setTimeTracerId,
  setModule,
  resetTimeTrackingInfo,
} = timeTrackerSlice.actions;
export default timeTrackerSlice.reducer;
