/**
 * External dependencies
 */
import { Layout, Avatar, Dropdown } from "antd";
import styled from "styled-components";

const { Header } = Layout;

/**
 * Styled Header component.
 * Extends Ant Design's Header component with custom styling.
 */
export const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: 64px;
  color: #333333;

  .ant-typography {
    color: #333333;
  }
`;

/**
 * Styled container for header icons.
 */

export const AlertContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 16px;

  .anticon {
    font-size: 20px;
    color: #30688e;
    margin-left: 16px;
    cursor: pointer;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    padding: 0.5rem 1.5rem;
  }
  .ant-dropdown-menu-item:hover {
    background-color: rgba(0, 128, 128, 0.1);
  }

  .ant-dropdown-menu-item-icon {
    color: #30688e;
  }
`;

/**
 * Styled Avatar component.
 */
export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

/**
 * Styled UserInfo component.
 */
export const UserInfo = styled.span`
  margin-left: 8px;
  color: #333333;
`;
