import {
  useCreateDeviceOrderMutation,
  useGetAllDevicesQuery
} from "@/store/features/devices/deviceApiSlice";
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin
} from "antd";
import { deviceTypeOptions } from "./../../constants/globalConstants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const DeviceOrderForm = ({ patientInfo }) => {
  const { data: devices, isSuccess, isLoading } = useGetAllDevicesQuery({});

  const [open, setOpen] = useState(false);

  const form = Form.useForm();

  const [createOrder] = useCreateDeviceOrderMutation();

  console.log({ patientInfo, devices });
  const navigate = useNavigate();

  let content = null;

  if (isLoading) {
    content = (
      <Flex align="center" justify="center">
        <Spin />
      </Flex>
    );
  }

  const handleModalOk = () => {
    setOpen(false);
    navigate("/device-order-list"); // Replace with your actual orders list route
  };

  const handleModalCancel = () => {
    setOpen(false);
    form.resetFields(); // Reset the form fields
  };

  let payload = {};

  const onFinish = async (values) => {
    console.log(values);
    payload = {
      patientId: Number(patientInfo.patientId), // Use the patientId from patientInfo prop
      authorizationCode: values.authorizationCode, // Use the authorizationCode from the form
      deviceList: values.deviceIdS.map((deviceId) => ({
        deviceId: parseInt(deviceId, 10)
      }))
    };

    try {
      const response = await createOrder(payload).unwrap();
      console.log("Order created successfully:", response);
      if (response.isSuccess) {
        setOpen(true);
      }

      // Add success message or redirect here
    } catch (error) {
      console.error("Failed to create order:", error);
      // Add error handling here (e.g., display error message to user)
    }

    // ... (rest of the component remains the same)
  };

  const mapDeviceOptions = (devices) => {
    return devices?.map((device) => ({
      label: device.deviceName,
      value: device.deviceId
    }));
  };

  if (isSuccess) {
    content = (
      <Form
        requiredMark
        initialValues={patientInfo}
        style={{ marginTop: "2rem" }}
        layout="vertical"
        onFinish={onFinish}
      >
        {/* Mobile NO */}
        <Row gutter={[15, 15]}>
          <Col span={8}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input the first name!"
                }
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="middleName" label="Middle Name">
              <Input placeholder="Middle Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input the last name!"
                }
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>

        {/* Address Line */}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Address Line"
              rules={[
                {
                  required: true,
                  message: "Please Enter Address!"
                }
              ]}
            >
              <Input placeholder="Address Line" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please Enter City!"
                }
              ]}
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
        </Row>

        {/* City */}

        <Row gutter={16}></Row>

        <Col span={12}>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your phone number!"
              }
            ]}
          >
            <Input type="text" placeholder="Phone Number" />
          </Form.Item>
        </Col>

        {/* {Device type,Device List} */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="deviceType"
              label="Device Type"
              rules={[
                {
                  required: true,
                  message: "Please select the Device Type!"
                }
              ]}
            >
              <Select placeholder="select device" options={deviceTypeOptions} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="deviceIdS"
              label="Device List"
              rules={[
                {
                  required: true,
                  message: "Please select the Device List!"
                }
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Device"
                options={mapDeviceOptions(devices)}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Authentication Code */}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="authorizationCode"
              label="Authentication Code"
              rules={[
                {
                  required: true,
                  message: "Please Enter Code!"
                }
              ]}
            >
              <Input placeholder="Enter Code" />
            </Form.Item>
          </Col>
        </Row>

        {/* Divider */}

        <Divider />

        {/* Cancel & Create Order Buttons */}
        <Row justify={"end"} gutter={11}>
          <Col span={3}>
            <Form.Item>
              <Button
                style={{ borderRadius: "7px", padding: "2px 17px" }}
                htmlType="submit"
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Form.Item>
            <Button
              type="primary"
              style={{ borderRadius: "7px", padding: "2px 17px" }}
              htmlType="submit"
            >
              Create Order
            </Button>
          </Form.Item>
        </Row>

        <Modal
          title="Order Created Successfully"
          open={open}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          footer={[
            <Button key="back" onClick={handleModalCancel}>
              Stay Here
            </Button>,
            <Button key="submit" type="primary" onClick={handleModalOk}>
              Go to Orders List
            </Button>
          ]}
        >
          <p>
            Your order has been created successfully. What would you like to do
            next?
          </p>
        </Modal>
      </Form>
    );
  }

  return content;
};

export default DeviceOrderForm;
