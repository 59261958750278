import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carePlan: "rpm",
  currentPatientHealthId: null,
  isQuickNoteModalOpen: false,
  patientId: null,
};

const patientDetailsSlice = createSlice({
  name: "patientDetails",
  initialState,
  reducers: {
    setCarePlan: (state, action) => {
      state.carePlan = action.payload;
    },
    setCurrentPatientHealthId: (state, action) => {
      console.log(action.payload);
      state.currentPatientHealthId = action.payload;
    },
    setIsQuickNoteModalOpen: (state, action) => {
      const payload = action.payload;
      state.patientId = payload.patientId;
      state.isQuickNoteModalOpen = payload.isQuickNoteModalOpen;
    },
  },
});

export const {
  setCarePlan,
  setCurrentPatientHealthId,
  setIsQuickNoteModalOpen,
} = patientDetailsSlice.actions;
export default patientDetailsSlice.reducer;
