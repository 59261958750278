import { createContext, useContext, useEffect, useState } from "react";
import PubNub from "pubnub";

const PubNubContext = createContext();

export const usePubNub = () => {
  const context = useContext(PubNubContext);
  if (!context) {
    throw new Error("usePubNub must be used within a PubNubProvider");
  }
  return context;
};

export const usePubNubConnection = (userName) => {
  const [pubnub, setPubnub] = useState(null);
  const [pamEnabled, setPamEnabled] = useState(false);
  const publishKey = import.meta.env.VITE_PUBNUB_PUBLISH_KEY;
  const subscribeKey = import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY;

  useEffect(() => {
    if (!publishKey || !subscribeKey) return;

    const pubnubConfig = new PubNub({
      publishKey: publishKey,
      subscribeKey: subscribeKey,
      userId: userName,
      fileUploadPublishRetryLimit: 0,
      autoNetworkDetection: true,
      restore: true,
    });

    pubnubConfig.addListener({
      status: function (status) {
        if (status.category === "PNAccessDeniedCategory") {
          setPamEnabled(true);
        }
      },
    });

    // Setup new PubNub user
    pubnubConfig.objects.getUUIDMetadata({}, (status) => {
      if (status.error) {
        pubnubConfig.objects.setUUIDMetadata({
          userName: userName,
        });
      }
    });

    setPubnub(pubnubConfig);

    return () => {
      pubnubConfig.unsubscribeAll();
      pubnubConfig.stop();
    };
  }, [userName, publishKey, subscribeKey]);

  return { pubnub, pamEnabled };
};

export const PubNubProvider = PubNubContext.Provider;
