import { Outlet, useLocation, useParams } from "react-router-dom";
import Header from "./quickInfo";
import { OutletContainer } from "./styles";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  resetTimeTrackingInfo,
  setModule,
  setPatientId,
} from "@/store/features/timeTracking/timeTrackerSlice";

const rpmPaths = [
  "blood-pressure",
  "pulse",
  "spo2",
  "glucose",
  "temperature",
  "weight",
];

export default function PatientDetailsParent() {
  const pathName = useLocation().pathname?.split("/")?.at(-1);
  const dispatch = useDispatch();
  const { patientId } = useParams();
  useEffect(() => {
    dispatch(setPatientId(patientId));
    if (rpmPaths.includes(pathName)) {
      dispatch(
        setModule({
          moduleName: "RPM",
          cptCode: 11,
        })
      );
    } else {
      dispatch(
        setModule({
          moduleName: "Patient Details",
          cptCode: 1,
        })
      );
    }
    return () => {
      dispatch(resetTimeTrackingInfo());
    };
  }, [pathName, dispatch, patientId]);
  return (
    <div>
      <Header />
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </div>
  );
}
